import React, { FC } from 'react';
import './TextImage.scss';
import { TTextImage } from '../../../../types/types';

const TextImage: FC<TTextImage> = (props) => {
    return (
        <div
            data-testid="text-image"
            id={props.id}
            className={`text-image__wrapper text-image__wrapper--${props.position}`}
        >
            <div className={`text-image__body text-image__body--${props.shadow}`}>
                <img
                    className={`text-image__images text-image__images--${props.relative}`}
                    src={props.image}
                    alt={props.imgDescription}
                />
            </div>
        </div>
    );
};

export default TextImage;
