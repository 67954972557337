import React, { FC, useState } from 'react';
import SectionTitle from '../../../common/SectionTitle';
import './Counter.scss';
import { ICounter } from '../../../../types/types';

const Counter: FC<ICounter> = (props) => {
    const [iconType, setIconType] = useState<boolean>(false);

    return (
        <div data-testid="counter" className="counter">
            <div className="counter__header">
                <SectionTitle type="lighter" title="Your votes" />
            </div>
            <div className="counter__wrapper">
                <SectionTitle type="secondary" title="On-Demand Team Extension" />
                <div className="counter__body">
                    <span className="counter__number">{props.leftCounter}</span>
                    <span className="counter__number">:</span>
                    <span className="counter__number">{props.rightCounter}</span>
                </div>
                <SectionTitle type="secondary" title="Fully Managed It Services" />
            </div>
        </div>
    );
};
export default Counter;
