import React, { FC, useState } from 'react';
import { TDropDownText } from '../../../../../types/types';

import showIcon from '../../../../../assets/img/workingTogether/show-icon.svg';

import './DropDownText.scss';

const DropDownText: FC<TDropDownText> = (props) => {
    const [icon, setIcon] = useState<boolean>(true);
    const toggleIcon = () => {
        setIcon(!icon);
    };

    return (
        <div data-testid="drop-down-text" className="drop-down-text">
            <div className="drop-down-text__wrapper">
                <div className="drop-down-text__header">
                    <span className="drop-down-text__title">{props.title}</span>
                    <img
                        onClick={toggleIcon}
                        className={
                            icon
                                ? 'drop-down-text__icon'
                                : 'drop-down-text__icon drop-down-text__icon--close'
                        }
                        src={showIcon}
                        alt="Show"
                    />
                </div>
                <div
                    className={
                        icon
                            ? 'drop-down-text__body'
                            : 'drop-down-text__body drop-down-text__body--show'
                    }
                >
                    <div className="drop-down-text__salary">
                        <span className="drop-down-text__time">Hourly</span>
                        <span className="drop-down-text__rate">$25-$65+/hour</span>
                    </div>
                    <div className="drop-down-text__salary">
                        <span className="drop-down-text__time">Part-time</span>
                        <span className="drop-down-text__rate">$500-$1,200+/week</span>
                    </div>
                    <div className="drop-down-text__salary">
                        <span className="drop-down-text__time">Full-time</span>
                        <span className="drop-down-text__rate">$1,000-$2,600+/week</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DropDownText;
