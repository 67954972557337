import React, { FC } from 'react';
import { ICounterRowProps } from '../../../../types/types';

const CounterRow: FC<ICounterRowProps> = (props) => {
    return (
        <div className="counter__points">
            <img src={props.leftIMG} alt="" />
            <span className="counter__title">{props.title}</span>
            <img src={props.rightIMG} alt="" />
        </div>
    );
};

export default CounterRow;
