import React, { FC } from 'react';
import './SectionName.scss';
import SectionTitle from '../../../common/SectionTitle';
import TextDescription from '../../../common/TextDescription';
import TextImage from '../../../pagesComponents/WorkingTogether/TextImage';
import { ISectionNameProps } from '../../../../types/types';

const SectionName: FC<ISectionNameProps> = (props) => {
    return (
        <div className="title__wrapp">
            <SectionTitle type="lighter" subtype="pl-15" title={props.mainTitle} />
            <div
                data-testid="section-name"
                className="working-together__body working-together__body--mt"
            >
                <div className="working-together__body-wrapper">
                    <TextDescription subtype="mb">{props.textDescFirst}</TextDescription>
                    <TextDescription subtype="mb">{props.textDescSecond}</TextDescription>
                    <TextDescription subtype="mb">{props.textDescThird}</TextDescription>
                    <TextDescription subtype="mb">{props.textDescFours}</TextDescription>
                </div>
                <TextImage
                    id={props.imageID}
                    large="large"
                    image={props.image}
                    relative="relative"
                    typeValue="lighter"
                    position="center-mobile-r"
                />
            </div>
        </div>
    );
};

export default SectionName;
