import React, { FC } from 'react';
import './RecomendedText.scss';
import { IRecomendedText } from '../../../../types/types';

const RecomendedText: FC<IRecomendedText> = (props) => {
    const textPoints = props.textPoints;

    return (
        <div data-testid="recomended-text" className="recomended-text__wrapper">
            <span className="recomended-text__title">{props.title}</span>
            <ul className="recomended-text__list">
                {textPoints.map((point, index) => (
                    <li key={index} className="recomended-text__points">
                        {point}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default RecomendedText;
